import { j as r, c as i, a as j, L as p } from "./ToggleSwitch-DvVsR0h0.mjs";
import { useId as x } from "react";
const N = "_JSONPrettyPrintMain_qmy6t_2", b = "_string_qmy6t_6", $ = "_number_qmy6t_10", C = "_boolean_qmy6t_14", k = "_key_qmy6t_22", o = {
  JSONPrettyPrintMain: N,
  string: b,
  number: $,
  boolean: C,
  null: "_null_qmy6t_18",
  key: k
};
function w() {
  const t = /* @__PURE__ */ new WeakSet();
  return (n, e) => {
    if (!(typeof Node == "function" && e instanceof Node)) {
      if (typeof e == "object" && e !== null) {
        if (t.has(e))
          return;
        t.add(e);
      }
      return e;
    }
  };
}
function P(t, n = 2) {
  return JSON.stringify(t, w(), n);
}
function S(t) {
  return t = t.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;"), t.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    (n) => {
      let e = o.number;
      return /^"/.test(n) ? /:$/.test(n) ? e = o.key : e = o.string : /true|false/.test(n) ? e = o.boolean : /null/.test(n) && (e = o.null), e ?? (e = ""), `<span class='${e}'>${n}</span>`;
    }
  );
}
function nt({ json: t }) {
  const n = typeof t == "string" ? t : P(t, 2), e = S(n);
  return /* @__PURE__ */ r.jsx("pre", { className: o.JSONPrettyPrintMain, dangerouslySetInnerHTML: { __html: e } });
}
const I = "_alert_1iits_1", v = "_icon_1iits_5", L = "_iconContainer_1iits_10", _ = {
  alert: I,
  icon: v,
  iconContainer: L,
  "icon-success": "_icon-success_1iits_15",
  "icon-warning": "_icon-warning_1iits_19",
  "icon-error": "_icon-error_1iits_23"
}, A = "_alert_w0jma_1", g = {
  alert: A,
  "kind-success": "_kind-success_w0jma_9",
  "kind-warning": "_kind-warning_w0jma_14",
  "kind-error": "_kind-error_w0jma_19"
};
function J({ kind: t, className: n, style: e, children: s, ...c }) {
  const a = t === "error" ? "alert" : "status";
  return /* @__PURE__ */ r.jsx("div", { ...c, style: e, className: i(g.alert, n, g[`kind-${t}`]), role: a, children: s });
}
const M = {
  warning: "alertTriangle",
  error: "alertCircle",
  success: "checkCircle"
};
function st({ kind: t, className: n, style: e, children: s }) {
  const c = t === "error" ? "alert" : "status";
  return /* @__PURE__ */ r.jsxs(J, { kind: t, role: c, className: i(_.alert, n), style: e, children: [
    /* @__PURE__ */ r.jsx("div", { className: i(_[`icon-${t}`], _.iconContainer), children: /* @__PURE__ */ r.jsx(j, { name: M[t], className: _.icon, title: t }) }),
    /* @__PURE__ */ r.jsx("div", { children: s })
  ] });
}
const q = "_container_n16ki_1", O = "_neutral_n16ki_20", F = "_success_n16ki_25", f = {
  container: q,
  default: "_default_n16ki_14",
  neutral: O,
  success: F
};
function rt({ kind: t = "default", children: n }) {
  return /* @__PURE__ */ r.jsx("div", { className: i(f.container, f[t]), children: n });
}
const H = "_container_1yuy9_1", z = {
  container: H
}, ct = ({ as: t = "div", className: n, children: e, ...s }) => /* @__PURE__ */ r.jsx(t, { ...s, className: i(z.container, n), children: e }), T = "_center_m0sxl_1", B = "_centerIntrinsic_m0sxl_11", m = {
  center: T,
  centerIntrinsic: B
};
function it({
  as: t = "div",
  maxSize: n = "fix-content-total",
  gutter: e = "300",
  className: s,
  centeredChildren: c,
  style: a,
  ...l
}) {
  return /* @__PURE__ */ r.jsx(
    t,
    {
      ...l,
      className: i(m.center, c && m.centerIntrinsic, s),
      style: {
        "--container-size": `var(--betterplace-sizing-${n})`,
        "--container-gutters": `var(--betterplace-spacing-${e})`,
        ...a
      },
      children: l.children
    }
  );
}
const R = {
  h100: "h1",
  h200: "h2",
  h300: "h3",
  h400: "h4"
}, E = "_h100_1tycd_3", G = "_h200_1tycd_7", Z = "_h300_1tycd_11", D = "_h400_1tycd_15", K = {
  h100: E,
  h200: G,
  h300: Z,
  h400: D
}, at = ({
  as: t,
  level: n,
  children: e,
  subtitle: s,
  className: c,
  ["aria-describedby"]: a,
  ...l
}) => {
  const h = t || R[n], y = x();
  return /* @__PURE__ */ r.jsxs(p, { space: "50", children: [
    /* @__PURE__ */ r.jsx(
      h,
      {
        ...l,
        className: i(K[n], c),
        "aria-describedby": s ? y : a,
        children: e
      }
    ),
    s ? /* @__PURE__ */ r.jsx("div", { id: y, "aria-hidden": !0, children: s }) : null
  ] });
}, Q = "_cluster_1iwsd_1", U = "_clustered_1iwsd_7", V = "_justified_1iwsd_11", u = {
  cluster: Q,
  clustered: U,
  justified: V
};
function ot({
  as: t = "div",
  alignment: n = "clustered",
  className: e,
  style: s,
  space: c = "200",
  ...a
}) {
  return /* @__PURE__ */ r.jsx(
    t,
    {
      ...a,
      className: i(u.cluster, e, {
        [u.justified]: n === "justified",
        [u.clustered]: n === "clustered"
      }),
      style: {
        ...s,
        "--space": `var(--betterplace-spacing-${c})`
      },
      children: a.children
    }
  );
}
const W = "_grid_jxunh_1", X = "_gridAutoFit_jxunh_6", Y = "_gridColumns_jxunh_10", d = {
  grid: W,
  gridAutoFit: X,
  gridColumns: Y
};
function lt({ children: t, space: n = "200", columns: e, minElementWidth: s, style: c }) {
  return /* @__PURE__ */ r.jsx(
    "div",
    {
      className: i(d.grid, {
        [d.gridAutoFit]: !e && s,
        [d.gridColumns]: !!e
      }),
      style: {
        ...c,
        "--space": `var(--betterplace-spacing-${n})`,
        //   We might want to extend this to use our tokens
        "--min": s,
        "--columns": e
      },
      children: t
    }
  );
}
export {
  st as A,
  rt as B,
  J as C,
  at as H,
  nt as J,
  ot as L,
  ct as a,
  it as b,
  lt as c
};
